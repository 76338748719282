import { render, staticRenderFns } from "./Operation.vue?vue&type=template&id=04b7b87e&scoped=true&"
import script from "./Operation.vue?vue&type=script&lang=js&"
export * from "./Operation.vue?vue&type=script&lang=js&"
import style0 from "./Operation.vue?vue&type=style&index=0&id=04b7b87e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04b7b87e",
  null
  
)

export default component.exports